import { render, staticRenderFns } from "./CustomTable.vue?vue&type=template&id=06d02afe&scoped=true"
import script from "./CustomTable.vue?vue&type=script&lang=js"
export * from "./CustomTable.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "06d02afe",
  null
  
)

export default component.exports